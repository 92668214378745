import axios, { AxiosResponse } from "axios";
import { stringify } from "querystring";
import {
  cognitoSupportClientDomain,
  cognitoClientDomain,
  cognitoAppSupportClientId,
  cognitoAppClientId,
  cognitoAppSupportClientSecret,
  cognitoAppClientSecret,
} from "src/config/config";

const retrieveToken = async (code: string): Promise<AxiosResponse> => {
  const appStore = JSON.parse(sessionStorage.getItem("AppStore") || "");
  const isSupport = appStore.state.isSupportSession;
  const appDomain = isSupport ? cognitoSupportClientDomain : cognitoClientDomain;
  const appClientId = isSupport ? cognitoAppSupportClientId : cognitoAppClientId;
  const appSecret = isSupport ? cognitoAppSupportClientSecret : cognitoAppClientSecret;

  const requestBody = {
    grant_type: "authorization_code",
    client_id: appClientId,
    code: code,
    redirect_uri: `${window.location.protocol}//${window.location.host}`,
  };
  const authString = `${appClientId}:${appSecret}`;
  const response = await axios(`${appDomain}/oauth2/token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Basic ${btoa(authString)}`,
    },
    data: stringify(requestBody),
  });
  return response;
};

export default retrieveToken;
