import { FC, memo } from "react";
import { Typography } from "antd";
import { TitleProps } from "antd/lib/typography/Title";

const Title: FC<TitleProps> = memo((props) => {
  return <Typography.Title {...props} />;
});
Title.displayName = "Title";

export default Title;
