import { lazy } from "react";
import { IRoute } from "./types/Route";

const Zones = lazy(() => import("./pages/Zones"));
const ZonesGroups = lazy(() => import("./pages/Zones/Groups"));
const ZonesGroupsAdd = lazy(() => import("./pages/Zones/Groups/Add"));
const ZonesGroupsEdit = lazy(() => import("./pages/Zones/Groups/Edit"));

const Campaigns = lazy(() => import("./pages/Campaigns"));
const CampaignAdd = lazy(() => import("./pages/Campaigns/Add"));
const CampaignEdit = lazy(() => import("./pages/Campaigns/Edit"));
const CampaignRecycleRecord = lazy(() => import("./pages/RecycleRecordsPage"));

const CampaignGroups = lazy(() => import("./pages/CampaignGroups"));
const CampaignGroupsAdd = lazy(() => import("./pages/CampaignGroups/Add"));
const CampaignGroupsEdit = lazy(() => import("./pages/CampaignGroups/Edit"));

const OperatingHours = lazy(() => import("./pages/OperatingHours"));
const OperatingHourAdd = lazy(() => import("./pages/OperatingHours/Add"));
const OperatingHourEdit = lazy(() => import("./pages/OperatingHours/Edit"));

const EventClosures = lazy(() => import("./pages/EventClosures"));
const EventClosureAdd = lazy(() => import("./pages/EventClosures/Add"));
const EventClosureEdit = lazy(() => import("./pages/EventClosures/Edit"));

const Schemas = lazy(() => import("./pages/SchemaListPage"));
const Disposition = lazy(() => import("./pages/Dispositions"));
const DispositionGroups = lazy(() => import("./pages/Dispositions/Groups"));
const DispositionGroupsAdd = lazy(() => import("./pages/Dispositions/Groups/Add"));
const DispositionGroupsEdit = lazy(() => import("./pages/Dispositions/Groups/Edit"));

const Users = lazy(() => import("./pages/User"));
const UsersAdd = lazy(() => import("./pages/User/Add"));
const UsersEdit = lazy(() => import("./pages/User/Edit"));

const Agents = lazy(() => import("./pages/Agents"));

const routes: IRoute[] = [
  {
    Component: Campaigns,
    path: "campaigns",
    navIconType: "nav-campaigns",
    navTitle: "Campaigns",
    navOrder: 1,
    isProtected: true,
    routes: [
      {
        Component: CampaignAdd,
        path: "add",
        isProtected: true,
      },
      {
        Component: CampaignEdit,
        path: "edit",
        isProtected: true,
      },
      {
        Component: CampaignRecycleRecord,
        path: "recycle-records",
        isProtected: true,
      },
    ],
  },
  {
    Component: CampaignGroups,
    path: "campaign-groups",
    navIconType: "nav-campaign-groups",
    navTitle: "Campaign groups",
    navOrder: 2,
    isProtected: true,
    routes: [
      {
        Component: CampaignGroupsAdd,
        path: "add",
        isProtected: true,
      },
      {
        Component: CampaignGroupsEdit,
        path: "edit",
        isProtected: true,
      },
    ],
  },
  {
    Component: OperatingHours,
    path: "operating-hours",
    navIconType: "nav-operating-hours",
    navTitle: "Operating hours",
    navOrder: 5,
    isProtected: true,
    routes: [
      {
        Component: OperatingHourAdd,
        path: "add",
        isProtected: true,
      },
      {
        Component: OperatingHourEdit,
        path: "edit",
        isProtected: true,
      },
    ],
  },
  {
    Component: Zones,
    path: "zones",
    isProtected: true,
  },
  {
    Component: ZonesGroups,
    navIconType: "nav-zones",
    navTitle: "Zones",
    navOrder: 6,
    path: "zones/groups",
    isProtected: true,
    routes: [
      {
        Component: ZonesGroupsAdd,
        path: "add",
        isProtected: true,
      },
      {
        Component: ZonesGroupsEdit,
        path: "edit",
        isProtected: true,
      },
    ],
  },
  {
    Component: EventClosures,
    path: "event-closures",
    navIconType: "nav-event-closures",
    navTitle: "Event closures",
    navOrder: 7,
    isProtected: true,
    routes: [
      {
        Component: EventClosureAdd,
        path: "add",
        isProtected: true,
      },
      {
        Component: EventClosureEdit,
        path: "edit",
        isProtected: true,
      },
    ],
  },
  {
    Component: Schemas,
    path: "schemas",
    navIconType: "nav-schemas",
    navTitle: "Schema",
    navOrder: 3,
    isProtected: true,
  },
  {
    Component: Disposition,
    path: "dispositions",
    isProtected: true,
  },
  {
    Component: DispositionGroups,
    path: "dispositions/groups",
    navIconType: "nav-dispositions",
    navTitle: "Dispositions",
    navOrder: 4,
    isProtected: true,
    routes: [
      {
        Component: DispositionGroupsAdd,
        path: "add",
        isProtected: true,
      },
      {
        Component: DispositionGroupsEdit,
        path: "edit",
        isProtected: true,
      },
    ],
  },
  {
    Component: Users,
    path: "users",
    navIconType: "nav-users",
    navTitle: "Users",
    navOrder: 8,
    isProtected: true,
    routes: [
      {
        Component: UsersAdd,
        path: "add",
        isProtected: true,
      },
      {
        Component: UsersEdit,
        path: "edit",
        isProtected: true,
      },
    ],
  },
  {
    Component: Agents,
    path: "agents",
    navIconType: "agent",
    navTitle: "Agents",
    navOrder: 9,
    isProtected: true,
  },
];

export default routes;
