import { Spin } from "antd";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAppStore from "src/controller/app/store";

const WelcomePage: FC = () => {
  const { isAuthenticated, userDetails } = useAppStore();
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => (!isAuthenticated ? navigate("/login") : navigate("/campaigns")), 2000);
  }, [isAuthenticated, navigate, userDetails]);

  return (
    <div style={{ paddingTop: "200px", textAlign: "center" }}>
      <Spin spinning={true} />
    </div>
  );
};

export default WelcomePage;
