import LogRocket from "logrocket";
import { Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import SuspenseFallback from "src/common/SuspenseFallback";
import useAppStore from "src/controller/app/store";
import useAppInitialization from "src/hooks/useAppInitialization";
import Layout from "src/layouts";
import LoggedOut from "src/pages/LoggedOut";
import Login from "src/pages/Login";
import NoMatchPage from "src/pages/NoMatchPage";
import Support from "src/pages/Support";
import WelcomePage from "src/pages/WelcomePage";
import routes from "src/routes";
import ProtectedRoute from "./components/ProtectedRoute";
import routesGenerator from "./utils/routesGenerator";

function App() {
  useAppInitialization();

  const { userDetails } = useAppStore();

  useEffect(() => {
    if (userDetails.isAuthorized) {
      LogRocket.identify(userDetails.id, {
        name: userDetails.fullName.replaceAll("|", " "),
        email: userDetails.id,

        //Add custom attribute below:
      });
    }
  }, [userDetails]);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<WelcomePage />} />
        {routesGenerator(routes, ({ Component, path, isProtected }) => {
          return (
            Component && (
              <Route
                key={path}
                path={path}
                element={
                  <Suspense fallback={<SuspenseFallback />}>
                    {isProtected ? (
                      <ProtectedRoute redirectPath="/login">
                        <Component />
                      </ProtectedRoute>
                    ) : (
                      <Component />
                    )}
                  </Suspense>
                }
              />
            )
          );
        })}
      </Route>

      <Route path="login" key="/login" element={<Login />} />

      <Route path="support" key="/support" element={<Support />} />

      <Route path="logout" key="/logout" element={<LoggedOut />} />
      {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
      <Route path="*" element={<NoMatchPage />} />
    </Routes>
  );
}

export default App;
