import { UserOutlined } from "@ant-design/icons";
import { Button, Layout, Popover, Row, Space, Tag } from "antd";

import { FC, useCallback, useEffect, useState } from "react";
import { Outlet } from "react-router";
import SideBar from "src/components/App/components/SideBar";
import styled from "styled-components";
import Icon from "src/common/Icon";
import { useLogout } from "src/services/auth/logout";
import useAppStore from "src/controller/app/store";
import retrieveToken from "src/services/auth/retrieveToken";
import { large } from "src/utils/mediaQueries";
import getUserFromIdToken from "src/utils/auth/getUserFromIdToken";
import EnvironmentName from "src/components/App/components/AppHeader/EnvironmentName";
import { environmentName } from "src/config/config";

const { Header } = Layout;

const HeaderStyled = styled(Header)`
  background-color: white;
  color: black;
  padding: 0 24px;
  border-bottom: 2px solid var(--vf-border-color);
`;

const Container = styled.div`
  background-color: white;
  min-height: calc(100vh - 64px);
`;

const ButtonUserStyled = styled(Button)`
  transform: scale(0.9);
  ${large} {
    transform: initial;
  }
`;

const DivPopoverContentStyled = styled.div`
  text-align: center;
`;

const MainLayout: FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { logout } = useLogout();
  const url = new URL(window.location.href);
  const code = url.searchParams.get("code");
  const { setLogin, isAuthenticated, userDetails } = useAppStore();

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const readTokenFromCode = useCallback(async () => {
    if (!code) return;

    const response = await retrieveToken(code);

    const { id_token, access_token, refresh_token } = response.data;
    sessionStorage.setItem("id_token", id_token);
    sessionStorage.setItem("access_token", access_token);
    sessionStorage.setItem("refresh_token", refresh_token);

    const userDetails = await getUserFromIdToken(id_token!);
    if (userDetails.isAuthorized) {
      await setLogin(userDetails);
    }

    window.location.assign(`${window.location.protocol}//${window.location.host}`);
  }, [code, setLogin]);

  useEffect(() => {
    if (code) {
      readTokenFromCode();
    }
  }, [code, readTokenFromCode]);

  return (
    <Layout hasSider>
      {isAuthenticated && <SideBar collapsed={collapsed} />}
      <Layout style={{ marginLeft: collapsed ? 80 : 200, transition: "margin-left 0.2s" }}>
        <HeaderStyled>
          <Row justify="space-between" align="middle">
            <Button type="link" onClick={toggle}>
              <Icon type={"minimize"} fill={"black"} />
            </Button>
            <EnvironmentName environment={{ name: environmentName, color: "black" }} />
            {isAuthenticated && (
              <Popover
                content={
                  <DivPopoverContentStyled>
                    <h3>{userDetails.fullName.replace("|", " ")}</h3>
                    <Space direction="vertical">
                      <Button onClick={logout}>Sign out</Button>
                    </Space>
                  </DivPopoverContentStyled>
                }
                trigger="click"
              >
                {userDetails.isInternalUser && <Tag color="magenta">Logged in as Support</Tag>}
                <ButtonUserStyled shape="circle" icon={<UserOutlined />} />
              </Popover>
            )}
          </Row>
        </HeaderStyled>
        <Container>
          <Outlet />
        </Container>
      </Layout>
    </Layout>
  );
};

export const NoLayout: FC = () => {
  return (
    <Layout>
      <Container>
        <Outlet />
      </Container>
    </Layout>
  );
};

export default MainLayout;
