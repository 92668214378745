import { ReactNode } from "react";
import { IRoute, TCallbackGeneratorHandler } from "src/types/Route";

const routesGenerator = (values: IRoute[], callback: TCallbackGeneratorHandler): ReactNode => {
  return values.map(({ routes = [], ...props }) => {
    if (routes.length) {
      return routesGenerator([props, ...routes.map((item) => ({ ...item, path: `${props.path}/${item.path}` }))], callback);
    } else {
      return callback(props);
    }
  });
};

export default routesGenerator;
