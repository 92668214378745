import { IUser } from "src/types/User";
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

export type AppState = {
  userDetails: IUser;
  isAuthenticated: boolean;
  isSupportSession: boolean;
  displayDrawer: boolean;
  setLogin: (userDetails: IUser) => void;
  setLogout: () => void;
  setLoginAsSupport: () => void;
  setDisplayDrawer: (displayDrawer: boolean) => void;
};

const initialState = {
  userDetails: {
    id: "unknown",
    firstName: "",
    lastName: "",
    email: "",
    fullName: "anonymous|user",
    isInternalUser: false,
    isAuthorized: false,
  },
  isAuthenticated: false,
  isSupportSession: false,
  displayDrawer: false,
};

const useAppStore = create<AppState>()(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        setLogin: (userDetails): void => set({ isAuthenticated: true, userDetails: userDetails }),
        setLogout: (): void => set({ isAuthenticated: false, isSupportSession: false }),
        setLoginAsSupport: (): void => set({ isSupportSession: true }),
        setDisplayDrawer: (displayDrawer: boolean) => set({ displayDrawer }),
      }),
      {
        name: "AppStore",
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);

export default useAppStore;
