import { FC } from "react";

const NoMatchPage: FC = () => {
  return (
    <div>
      <p>Nothing here</p>
    </div>
  );
};

export default NoMatchPage;
