import { ReactNode } from "react";
import { IRoute, TCallbackGeneratorHandler } from "src/types/Route";

const navMenuItemsGenerator = (values: IRoute[], callback: TCallbackGeneratorHandler): ReactNode => {
  return values.map(({ routes = [], ...props }) => {
    return callback(props);
  });
};

export default navMenuItemsGenerator;
