import { Layout } from "antd";
import Sider from "antd/lib/layout/Sider";
import { FC } from "react";
import { medium, xlarge } from "src/utils/mediaQueries";
import styled from "styled-components";
import IconVfLogo from "../AppHeader/IconVfLogo";
import NavBarMenu from "../AppHeader/NavBarMenu";

const { Header } = Layout;

const HeaderStyled = styled(Header)`
  align-items: center;
  display: flex;
  height: 64px;
  padding: 0 10px;

  ${medium} {
    padding: 0 24px;
  }

  .anticon {
    margin-right: 0;
  }
`;

const DivDefaultLogoStyled = styled.div`
  align-items: center;
  color: #fff;
  display: flex;
  font-size: 36px;
  margin-right: 10px;
  flex: 0 1 50px;
  ${xlarge} {
    flex: initial;
  }
`;

const IconVfLogoStyled = styled(IconVfLogo)`
  width: 36px;
`;

const DivLogoStyled = styled(DivDefaultLogoStyled)`
  background-image: url("https://voicefoundry.com/wp-content/themes/voicefoundry/img/vf-logo-white.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100%;
  margin-right: 16px;
  flex: 1 0;
`;

interface TypeProps {
  collapsed: boolean;
}

const SideBar: FC<TypeProps> = ({ collapsed }) => {
  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      style={{
        overflow: "auto",
        width: "200px;",
        height: "100vh",
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
      }}
    >
      <HeaderStyled>
        {collapsed ? (
          <DivDefaultLogoStyled>
            <IconVfLogoStyled />
          </DivDefaultLogoStyled>
        ) : (
          <DivLogoStyled />
        )}
      </HeaderStyled>
      <NavBarMenu />
    </Sider>
  );
};

export default SideBar;
