import { useEffect } from "react";
import { useAppActions } from "src/controller/app/actions";
import styled from "styled-components";

const DivContainerStyled = styled.div`
  background: #eeeeee;
  height: 100vh;
  width: 100vw;
  position: fixed;
  text-align: center;
  z-index: 1;
`;

const Login = () => {
  const { doLogin } = useAppActions();

  useEffect(() => {
    // clear storage
    sessionStorage.clear();
    doLogin(false);
  }, [doLogin]);

  return (
    <DivContainerStyled>
      <p>Redirecting to login.</p>
    </DivContainerStyled>
  );
};
export default Login;
