import { useEffect } from "react";
import { useAppActions } from "src/controller/app/actions";
// import { DoLogin } from "src/services/auth/login";
import styled from "styled-components";

const DivContainerStyled = styled.div`
  background: #eeeeee;
  height: 100vh;
  width: 100vw;
  position: fixed;
  text-align: center;
  z-index: 1;
`;

const Support = () => {
  const { doLogin } = useAppActions();

  useEffect(() => {
    // clear storage
    sessionStorage.clear();
    doLogin(true);
  }, [doLogin]);

  return (
    <DivContainerStyled>
      <p>Redirecting to login as support</p>
    </DivContainerStyled>
  );
};
export default Support;
