import { FC, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import Menu from "antd/lib/menu";
import "src/styles/index.less"; // Import Ant Design styles by less entry

import Icon from "src/common/Icon";
import routes from "src/routes";
import navMenuItemsGenerator from "../../utils/navigationGenerator";
import useAppStore from "src/controller/app/store";

const NavBarMenu: FC = () => {
  const location = useLocation();
  const { userDetails } = useAppStore();

  const current = useMemo(() => {
    const path = location.pathname;
    return path;
  }, [location.pathname]);

  const getParentLastChildMenuSegment = (path: string): string[] => {
    const pathSnippets = path.split("/").filter((i) => i);
    const paths = pathSnippets.map((_, index) => `${pathSnippets.slice(0, index + 1).join("/")}`);

    return paths;
  };

  const selectedKey = getParentLastChildMenuSegment(current);

  return (
    <Menu theme="dark" defaultSelectedKeys={selectedKey} selectedKeys={selectedKey} mode="inline">
      {userDetails.isAuthorized &&
        navMenuItemsGenerator(
          (routes || []).sort((a, b) => (a?.navOrder || 0) - (b?.navOrder || 0)),
          ({ Component, path, navIconType, navTitle }) => {
            return (
              Component &&
              navTitle && (
                <Menu.Item key={path} icon={<Icon type={navIconType} />}>
                  <Link to={path}>{navTitle}</Link>
                </Menu.Item>
              )
            );
          },
        )}
    </Menu>
  );
};

export default NavBarMenu;
