import stringify from "safe-json-stringify";

const logger = {
  log: console.log,
  info: console.info,
  warn: console.warn,
  error: function (message: string, ...args: unknown[]): void {
    console.error(message, args);
    storeError(message, args);
  },
};

export default logger;

const maxRecentErrors = 500;

const storeError = (message: string, args: unknown[]): void => {
  const datetimeStamp = new Date().toISOString();
  const log = stringify([datetimeStamp, message, ...args]);
  let currentLength = Number(localStorage.getItem("consoleErrors.length") || 0);
  if (currentLength < maxRecentErrors) {
    localStorage.setItem(`consoleErrors[${currentLength}]`, log);
    currentLength++;
    localStorage.setItem("consoleErrors.length", currentLength.toString());
  } else {
    let index = Number(localStorage.getItem("consoleErrors.index") || 0);
    localStorage.setItem(`consoleErrors[${index}]`, log);
    index++;
    if (index >= maxRecentErrors) index = 0;
    localStorage.setItem("consoleErrors.index", index.toString());
  }
};

export const listErrors = (): string => {
  let logs = "";
  const currentLength = Number(localStorage.getItem("consoleErrors.length") || 0);
  const index = Number(localStorage.getItem("consoleErrors.index") || 0);
  for (let i = index; i < currentLength; i++) logs += localStorage.getItem(`consoleErrors[${i}]`) + ",\r\n";
  for (let i = 0; i < index; i++) logs += localStorage.getItem(`consoleErrors[${i}]`) + ",\r\n";
  return logs;
};

export const clearErrorLog = (): void => {
  const currentLength = Number(localStorage.getItem("consoleErrors.length") || 0);
  for (let i = 0; i < currentLength; i++) localStorage.removeItem(`consoleErrors[${i}]`);
  localStorage.setItem("consoleErrors.length", "0");
  localStorage.setItem("consoleErrors.index", "0");
};

export const userBrowser = (): string => {
  // Return full userAgent string
  return window.navigator.userAgent;
};

export const userOperatingSystem = (): string => {
  let operatingSystem = "Not known";
  if (window.navigator.appVersion.indexOf("Win") !== -1) {
    operatingSystem = "Windows OS";
  }
  if (window.navigator.appVersion.indexOf("Mac") !== -1) {
    operatingSystem = "MacOS";
  }
  if (window.navigator.appVersion.indexOf("X11") !== -1) {
    operatingSystem = "UNIX OS";
  }
  if (window.navigator.appVersion.indexOf("Linux") !== -1) {
    operatingSystem = "Linux OS";
  }

  return operatingSystem;
};
