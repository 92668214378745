import { QueryClient } from "react-query";

class QueryClientInstance {
  private static instance: QueryClient;
  private constructor() {}

  static getInstance() {
    if (!QueryClientInstance.instance) {
      QueryClientInstance.instance = new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: true,
            refetchOnMount: true,
            refetchOnReconnect: true,
            refetchIntervalInBackground: true,
            retry: false,
            staleTime: 5 * 60 * 1000, /// 5 minutes
          },
        },
      });
    }
    return QueryClientInstance.instance;
  }
}

export default QueryClientInstance;
