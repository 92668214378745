import { FC } from "react";
import styled from "styled-components";
import { large, medium, small, xlarge } from "src/utils/mediaQueries";
import { TypeConfigEnvironment } from "src/types/TypeConfigEnvironment";
import Text from "src/common/Text";
import defaultConfigTheme from "src/utils/config/defaultConfigTheme.json";

interface TypeProps {
  environment?: TypeConfigEnvironment;
}

const TextStyled = styled(Text)<{ $color?: string; $length?: number }>`
  color: ${({ $color }): string => $color || defaultConfigTheme.theme.environment.color};
  font-size: 16px;
  line-height: 52px;
  ${small} {
    font-size: ${({ $length }) => ($length ? ($length > 15 ? `12px` : `16px`) : `16px`)};
  }
  ${medium} {
    font-size: ${({ $length }) => ($length ? ($length > 18 ? `14px` : `16px`) : `16px`)};
  }
  ${large} {
    font-size: 18px;
  }
  ${xlarge} {
    font-size: 22px;
  }
`;

const EnvironmentName: FC<TypeProps> = ({ environment }) => {
  if (environment) {
    return (
      <TextStyled $color={environment.color} $length={environment.name?.length}>
        {environment.name}
      </TextStyled>
    );
  }

  return null;
};

export default EnvironmentName;
