import { cognitoAppClientId, cognitoClientDomain } from "src/config/config";

export const useLogout = () => {
  const logout = async () => {
    // logout cognito
    const rUri = encodeURI(`${window.location.protocol}//${window.location.host}/logout`);
    const cognitoLogoutUrl = `${cognitoClientDomain}/logout?client_id=${cognitoAppClientId}&logout_uri=${rUri}&redirect_uri=${rUri}&response_type=code`;
    window.location.assign(cognitoLogoutUrl);
  };

  return { logout };
};
