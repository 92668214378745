import jwtDecode from "jwt-decode";

import { IUser, TCognitoIdTokenJson } from "src/types/User";

function getUserFromIdToken(idToken: string): IUser {
  const tokenJson = jwtDecode(idToken) as TCognitoIdTokenJson;
  const user: IUser = {
    id: tokenJson.email,
    email: tokenJson.email,
    firstName: tokenJson.firstName,
    lastName: tokenJson.lastName,
    fullName: tokenJson.fullName,
    isInternalUser: tokenJson.isInternalUser === "true",
    isAuthorized: tokenJson.isAuthorized === "true",
  };
  return user;
}

export default getUserFromIdToken;
